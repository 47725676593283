
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeGET, makePOST } from "../../helpers/makeRequest";
import { conf } from '../../config/config'
import 'react-phone-number-input/style.css'
import { MDBDataTableV5 } from 'mdbreact';
import Select from "react-select";


export const InicioScreen = () => {
  const user = useSelector((state) => state.auth);
  useEffect(() => {
    getDatos();

  }, []);
  const [datos, setDatos] = useState([]);
  const baseURL = conf.apiURL;
  const [adding, setAdding] = useState(false);

  const [estatusFilled, setEstatusFilled] = useState(false);

  const [copySuccess, setCopySuccess] = useState([])

  const [form, setForm] = useState({
    mx: undefined,
    name: undefined,
    state: undefined,
    phone: undefined,
    phone2: undefined,
    address: undefined,
    email: undefined,
    lat: undefined,
    lng: undefined,
    coordinates: [undefined, undefined],
    sitio: undefined,
    cotiza: undefined
  })

  const [flag, setFlag] = useState(false)

  const handleEdit = (obj) => {
    let tempObj = { ...obj }
    delete tempObj.estatus
    delete tempObj.buttons
    delete tempObj.copySuccess
    delete tempObj.fecha
    delete tempObj.estatus_button
    delete tempObj.id

    const rol = localStorage.getItem("idRol")

    if(rol == "3") {
      tempObj = {
        n_comercial: tempObj.n_comercial,
        correo: tempObj.correo,
        telefono: tempObj.telefono,
        facebook: tempObj.facebook,
      }
    }

    if(rol == "4") {
      tempObj = {
        n_comercial: tempObj.n_comercial,
        n_contacto: tempObj.n_contacto,
        correo: tempObj.correo,
        telefono: tempObj.telefono,
        lat: tempObj.lat,
        lng: tempObj.lng,
        direccion: tempObj.direccion,
        gbp: tempObj.gbp,
      }
    }

    setForm({ ...tempObj })
    setFlag(true)
    setAdding(true)
  };

  const estatuses = [
    { value: 1, label: "INICIADO" },
    { value: 2, label: "COMPLETADO" },
  ]

  const updateStatus = (val, idx, prop) => {
    let temp = { ...datos }
    temp.rows[idx][prop] = val.label
    temp.rows[idx].estatus_button =
      <div className="text-center">
        <Select
          options={estatuses}
          value={{ value: statutes[val.value], label: val.label }}
          autoFocus={false}
          isSearchable={false}
          onChange={(e) => {
            updateStatus(e, idx, "estatus")
          }}
        />
      </div>
    setDatos({ ...temp })
    makePOST("updateStatus", { id: temp.rows[idx].id, estatus: statutes[temp.rows[idx][prop]] })
  }

  const statutes = {
    "INICIADO": 1,
    "COMPLETADO": 2
  }

  useEffect(() => {
    if (datos.length != 0 && !estatusFilled) {
      let temp = { ...datos }
      temp.rows.map((element, idx) => {
        element.estatus_button =
          <div className="text-center">
            <Select
              options={estatuses}
              value={{ value: statutes[element.estatus], label: element.estatus }}
              autoFocus={false}
              isSearchable={false}
              onChange={(e) => {
                updateStatus(e, idx, "estatus")
              }}
            />
          </div>
      })
      setDatos({ ...temp })
      setEstatusFilled(true)
    }
  }, [datos])

  const getDatos = async () => {
    makeGET(["1", "2"].includes(localStorage.getItem("idRol")) ?
      "getRows" : "getRows?" + localStorage.getItem('email')).then(({ data }) => {
        data = data.data
        setCopySuccess(Object.keys(data[0]).map(v => { return false }))

        data.forEach((element, idx) => {
          element.buttons =
            <div className="text-center">
              <button key="update" onClick={e => { handleEdit(element) }} className="btn btn-sm btn-primary mr-1">
                <i className="fas fa-location-arrow"></i>
              </button>
            </div>
          /*element.estatus_button =
            <div className="text-center">
              <Select
                options={estatuses}
                value={{ value: element.estatus, label: element.estatus }}
                autoFocus={false}
                isSearchable={true}
                onChange={(e) => {
                  updateStatus(e, idx, "estatus")
                }}
              />
            </div>*/
        });
        const data_conf = {
          columns: [
            {
              label: 'Contacto',
              field: 'n_contacto'
            },
            {
              label: 'Nombre comercial',
              field: 'n_comercial',
            },
            {
              label: 'Fecha',
              field: 'fecha',
            },
            {
              label: 'Estatus',
              field: 'estatus_button',
            },
            {
              label: 'Info',
              field: 'buttons'
            }
          ],
          rows: data
        }
        setDatos(data_conf)
      }).catch((e) => {
        console.log(e)
        console.log(JSON.stringify(e))
      })
  };

  const resetForm = () => {
    Object.keys(form).map((key) => {
      form[key] = undefined
      if (key == "coordinates") {
        form[key] = [undefined, undefined]
      }
    })
  }
  useEffect(() => {
    //console.log(localStorage.getItem("selectedClient"))
  }, []);


  const toggleClose = () => {
    setAdding(false);
  };

  const copyToClipboard = async (e, v, i, id) => {
    e.preventDefault()

    navigator.clipboard.writeText(v)

    let tempCopy = [...copySuccess]
    tempCopy[i] = true
    setCopySuccess([...tempCopy])
    setTimeout(() => {
      tempCopy[i] = false
      setCopySuccess([...tempCopy])
    }, 1500);
  }

  function forceDownload(url) {
    console.log(url)
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(this.response);
      var tag = document.createElement('a');
      tag.style.display = "none"
      tag.href = imageUrl;
      tag.download = "image";
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    }
    xhr.send();
  }

  return (
    <>
      <div className="card m-3">
        <h5 className="card-header d-flex justify-content-between align-items-center">
          Registros
          {adding && (
            <button
              type="button"
              className="btn btn-sm btn-danger"
              onClick={() => {
                toggleClose()
                if (flag) {
                  setFlag(false)
                  resetForm()
                }
              }}
            >
              <i className="fas fa-times"></i>
            </button>
          )}
        </h5>
        {adding &&
          <div className="card-body row">
            {
              Object.keys(form).map((v, i) => (
                <div className={"col-6 col-lg-4 text-center rounded " +
                  (!(form.mx?.length == 0 && form.mx != undefined) &&
                    ' mb-3')}
                  style={{ backgroundColor: "whitesmoke" }}
                >
                  <p>{v}</p>
                  <div className="input-group mb-3 text-center" onClick={(e) => { copyToClipboard(e, form[v], i, v) }}>
                    <input type="text" className="form-control"
                      value={form[v]} id={v}
                      aria-describedby="basic-addon2" disabled={true} />
                    <div className="input-group-append" style={{ cursor: "pointer" }}>
                      <span className={"input-group-text " + (copySuccess[i] ? "bg-success text-white" : "")}
                        id="basic-addon2">
                        {copySuccess[i] ? <i className="fas fa-check-circle"></i> : <i className="fas fa-copy"></i>}
                      </span>
                    </div>
                  </div>
                  {v == "logo" &&
                    <img onClick={e => {
                      forceDownload(e.target.src)
                    }}
                      style={{
                        maxHeight: "200px", maxWidth: "200px",
                        height: "auto", width: "auto", cursor: "pointer"
                      }}
                      src={form[v]} className="mb-3"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = baseURL + "files/ltljj.png";
                      }}></img>
                  }
                </div>
              ))
            }
          </div>
        }
        <div className="card-body row">
          <div className="col-12">
            <MDBDataTableV5
              striped
              responsiveSm
              responsive
              responsiveMd
              responsiveLg
              responsiveXl
              hover
              entriesLabel="Por página"
              pagingTop
              infoLabel={["Página", " - ", "de", "registros"]}
              paginationLabel={["Anterior", "Siguiente"]}
              searchLabel="Buscar..."
              className="mdb"
              searchTop
              searchBottom={false}
              noBottomColumns
              data={datos}
            />
          </div>
        </div>
      </div>
    </>
  );
};
