
import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { makeDELETE, makeGET, makePATCH, makePOST } from "../../helpers/makeRequest";
import { conf } from '../../config/config'
import 'react-phone-number-input/style.css'
import Swal from "sweetalert2";
import TextField from '@mui/material/TextField'
import InputMask from 'react-input-mask'
import MenuItem from '@mui/material/MenuItem';
import { MDBDataTableV5 } from 'mdbreact';
import { makeStyles } from "@material-ui/core/styles";
import Select from "react-select";
import { Alert } from "@mui/material";
export const PortadaScreen = () => {
  const user = useSelector((state) => state.auth);
  useEffect(() => {
    getDatos();

  }, []);
  const [datos, setDatos] = useState([]);

  const [adding, setAdding] = useState(false);

  const [estatusFilled, setEstatusFilled] = useState(false);

  const [copySuccess, setCopySuccess] = useState([])
  const [copied, setCopied] = useState(false)

  const [form, setForm] = useState({
    mx: undefined,
    name: undefined,
    state: undefined,
    phone: undefined,
    phone2: undefined,
    address: undefined,
    email: undefined,
    lat: undefined,
    lng: undefined,
    coordinates: [undefined, undefined],
    sitio: undefined,
    cotiza: undefined
  })

  const [flag, setFlag] = useState(false)

  const clipBoardConstructor = (val, idx, key) => {
    return <div className="input-group mb-3 text-center input-group-sm" onClick={(e) => { copyToClipboard(e, val, idx, key) }}>
      <input type="text" className="form-control"
        value={val} id={idx}
        aria-describedby="basic-addon2" disabled={true} />
      <div className="input-group-append" style={{ cursor: "pointer" }}>
        <span className={"input-group-text " + ((copySuccess[idx]) ? "bg-success text-white" : "")}
          id="basic-addon2">
          {(copySuccess[idx]) ? <i className="fas fa-check-circle"></i> : <i className="fas fa-copy"></i>}
        </span>
      </div>
    </div>
  }

  const getDatos = async () => {
    makeGET(["1", "2"].includes(localStorage.getItem("idRol")) ?
    "getRows" : "getRows?" + localStorage.getItem('email')).then(({ data }) => {
      data = data.data

      let copySuccessTemp = data.map(v => {
        return [false, false, false, false]
      }).flat()

      setCopySuccess([...copySuccessTemp]) 
      let idxs = 0
      data.forEach((element, idx) => {

        element.n_comercial_button = clipBoardConstructor(element.n_comercial, idxs, "n_comercial_button")
        element.correo_button = clipBoardConstructor(element.correo, idxs + 1, "correo_button")
        element.telefono_button = clipBoardConstructor(element.telefono, idxs + 2, "telefono_button")
        element.facebook_button = clipBoardConstructor(element.facebook, idxs + 3, "facebook_button")
        idxs = idxs + 4
      });


      const data_conf = {
        columns: [
          {
            label: 'Nomre comercial',
            field: 'n_comercial_button'
          },
          {
            label: 'Correo',
            field: 'correo_button',
          },
          {
            label: 'Teléfono',
            field: 'telefono_button',
          },
          {
            label: 'Facebook',
            field: 'facebook_button',
          },
        ],
        rows: data
      }
      setDatos(data_conf)
    }).catch((e) => {
      console.log(e)
      console.log(JSON.stringify(e))
    })
  };

  const resetForm = () => {
    Object.keys(form).map((key) => {
      form[key] = undefined
      if (key == "coordinates") {
        form[key] = [undefined, undefined]
      }
    })
  }
  useEffect(() => {
    //console.log(localStorage.getItem("selectedClient"))
  }, []);

  const toggleClose = () => {
    setAdding(false);
  }

  const copyToClipboard = async (e, v, i) => {
    e.preventDefault()

    navigator.clipboard.writeText(v)

    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 1500);
  }

  return (
    <>
      <div className="card m-3">
        <h5 className="card-header d-flex justify-content-between align-items-center">
          Registros {copied && <Alert severity="success">Texto copiado!</Alert>}
          {adding && (
            <button
              type="button"
              className="btn btn-sm btn-danger"
              onClick={() => {
                toggleClose()
                if (flag) {
                  setFlag(false)
                  resetForm()
                }
              }}
            >
              <i className="fas fa-times"></i>
            </button>
          )}
        </h5>
        <div className="card-body row">
          <div className="col-12">
            <MDBDataTableV5
              striped
              responsiveSm
              responsive
              responsiveMd
              responsiveLg
              responsiveXl
              hover
              entriesLabel="Por página"
              pagingTop
              infoLabel={["Página", " - ", "de", "registros"]}
              paginationLabel={["Anterior", "Siguiente"]}
              searchLabel="Buscar..."
              className="mdb"
              searchTop
              searchBottom={false}
              noBottomColumns
              data={datos}
            />
          </div>
        </div>
      </div>
    </>
  );
};
