import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { startLogout } from '../../actions/auth';
import { useSelector, useDispatch } from 'react-redux';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { InicioScreen } from './InicioScreen';
import { PortadaScreen } from './PortadaScreen';
export const PrincipalScreen = () => {
    const { actualPage } = useParams()
    const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch(startLogout())
    }
    const history = useHistory()
    const user = useSelector(state => state.auth);
    const [page, setPage] = useState(actualPage == ':actualPage' ? (userData?.rol == "admin" ? 'franquicia' : 'inicio') : actualPage)

    const handleChangeOfRoute = (route) => {
        setPage(route)
        history.push('/' + route)
    }
    //const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("userProps")))
    const [userData, setUserData] = useState({})
    const [selectedClient, setSelectedClient] = useState((localStorage.getItem("selectedClient")))
    const [notSelectedClients, setNotSelectedClients] = useState([])
    const [reloading, setReloading] = useState(false)
    const changeClient = (val) => {
        setSelectedClient(val)
        localStorage.setItem('selectedClient', val)
        setReloading(true)
        setTimeout(() => {
            setReloading(false)
        }, 10)
    }
    useEffect(() => {
        setNotSelectedClients(userData?.clients?.filter(v => v != selectedClient) || [])
    }, [selectedClient])
    return (
        <>
            <Navbar expand="lg" bg="dark" variant="dark">
                <Container>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav>
                            <Nav.Link onClick={() => handleChangeOfRoute('inicio')}>
                                <span className="text-light">Registros <i className="fas fa-tasks"></i></span>
                            </Nav.Link>
                        </Nav>
                        {/* {["1", "2", "3"].includes(localStorage.getItem("idRol")) &&
                            <Nav>
                                <Nav.Link onClick={() => handleChangeOfRoute('portadas')}>
                                    <span className="text-light">Facebook <i className="fas fa-clipboard-list"></i></span>
                                </Nav.Link>
                            </Nav>
                        } */}
                        {
                            <Nav>
                                <Nav.Link onClick={() => handleChangeOfRoute('portadas')}>
                                    <span className="text-light">Facebook <i className="fas fa-clipboard-list"></i></span>
                                </Nav.Link>
                            </Nav>
                        }
                    </Navbar.Collapse>
                    <Navbar.Collapse className="justify-content-end">
                        {/* <Nav>
                            {notSelectedClients.length != 0 ?
                                <NavDropdown title={
                                    <span className="text-light">{String(selectedClient).toUpperCase()}</span>
                                } className="text-light" id="basic-nav-dropdown" style={{ color: 'white !important' }}>
                                    {notSelectedClients.map((val, idx) => (
                                        <NavDropdown.Item key={idx} className="font" onClick={(e) => {
                                            changeClient(val)
                                        }}>
                                            <span className="text-dark">{String(val).toUpperCase()}</span>
                                        </NavDropdown.Item>
                                    ))}
                                </NavDropdown>
                                :
                                <Nav.Link>
                                    <span className="text-light">{String(selectedClient).toUpperCase()}</span>
                                </Nav.Link>
                            }
                        </Nav> */}
                        <Nav>
                            <Nav.Link onClick={handleLogout}>
                                <span className="text-light">Salir </span>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {
                !reloading &&
                <>
                    {
                        (page == 'inicio') &&
                        <InicioScreen />
                    }
                    {/* {
                        (page == 'portadas' && ["1", "2", "3"].includes(localStorage.getItem("idRol"))) &&
                        <PortadaScreen />
                    } */}
                    {
                        (page == 'portadas') &&
                        <PortadaScreen />
                    }
                </>
            }
        </>
    )
}
