import axios from "axios";
import { startLogout, tokenSubject } from "../actions/auth.js";
import { conf } from "../config/config.js";
const baseURL = conf.apiURL;


export const makeGET = (endpoint = '', query = '') => {
    console.log("GET")
    let headers = {
        'Content-Type': 'application/json',
    }

    if (localStorage.getItem("token")) {
        headers.Authorization = localStorage.getItem("token")
    }

    return sec(axios.get(baseURL + endpoint + query, { headers }))
}

export const makePOST = (endpoint = '', body = '', isFormData = false) => {
    console.log("POST")
    let headers = {
        'Content-Type': isFormData ? 'multipart/form-date' : 'application/json',
    }

    const formatBody = isFormData ? body : JSON.stringify(body)

    if (localStorage.getItem("token")) {
        headers.Authorization = localStorage.getItem("token")
    }

    return sec(axios.post(baseURL + endpoint, formatBody, { headers }))
}

export const makeLogin = (endpoint = '', body = '', isFormData = false) => {
    console.log("POST")
    let headers = {
        'Content-Type': isFormData ? 'multipart/form-date' : 'application/json',
    }

    const formatBody = isFormData ? body : JSON.stringify(body)

    if (localStorage.getItem("token")) {
        headers.Authorization = localStorage.getItem("token")
    }

    return sec(axios.post("https://api-negocio.amarilio.net/" + endpoint, formatBody, { headers }))
}

export const makePATCH = (endpoint = '', body = '', isFormData = false) => {
    console.log("PATCH")
    let headers = {
        'Content-Type': isFormData ? 'multipart/form-date' : 'application/json',
    }

    const formatBody = isFormData ? body : JSON.stringify(body)

    if (localStorage.getItem("token")) {
        headers.Authorization = localStorage.getItem("token")
    }
    console.log(baseURL + endpoint, formatBody, { headers })
    return sec(axios.patch(baseURL + endpoint, formatBody, { headers }))
}

export const makeDELETE = (endpoint = '', body = '') => {
    console.log("DELETE")
    var data = JSON.stringify(body)
    var config = {
        method: 'delete',
        url: baseURL + endpoint,
        headers: {
            'Authorization': (localStorage.getItem("token")),
            'Content-Type': 'application/json'
        },
        data: data
    };

    return sec(axios(config))
}

const sec = async (request) => {
    try {
        let response = await request
        return response
    } catch (e) {
        console.log(e)
        const err = e.response || JSON.parse(JSON.stringify(e))
        if (err?.status == 422) {
            localStorage.removeItem('email')
            localStorage.removeItem('token')
            localStorage.removeItem('idRol')
            localStorage.removeItem('idUser')
            localStorage.removeItem('userProps')
            localStorage.removeItem('selectedClient')
            tokenSubject.next(null)
            window.location.reload(false);
        }
        throw e
    }
}